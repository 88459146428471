<template>
  <div class="row justify-center bg-grey-3">
    <q-card flat bordered style="width:300px">
      <q-card-section>
        <q-select
          outlined
          label="Murabbi/ah Tahfidz"
          v-model="selMurabbi"
          :options="murabbi"
          @input="getSiswa()"
        ></q-select>
      </q-card-section>
      <q-markup-table
        flat
        bordered
        dense
        wrap-cells
        separator="horizontal"
        class="stickyTable bg-grey-4"
        style="min-height:calc(100vh - 170px)"
      >
        <thead class="bg-indigo-5 text-white text-left">
          <tr>
            <th>no</th>
            <th>nama</th>
            <th class="text-center">jumlah halaman</th>
          </tr>
        </thead>
        <tbody class="bg-white">
          <tr v-for="(val, i) in listSiswa" :key="i">
            <td>{{ i + 1 }}</td>
            <td>{{ val.nama }}</td>
            <td class="text-center">
              {{ val.jml }}
            </td>
          </tr>
        </tbody>
      </q-markup-table>
    </q-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchTerm: "",

      murabbi: [],
      selMurabbi: null,

      listSiswa: [],
      filteredListSiswa: [],

      pagination: {
        current: 1,
        max: 99,
        limit: 10,
      },
    };
  },
  async mounted() {
    await this.getMurabbi();
  },
  methods: {
    async getSiswa() {
      let resp = await this.$http.get(
        `/hasil/rekaptahfidz/getsiswa/${this.selMurabbi.value}`
      );
      this.listSiswa = resp.data;
    },
    getShow() {
      let siswa = JSON.parse(JSON.stringify(this.listSiswa));

      if (this.searchTerm != "") {
        siswa = siswa.filter((val) => {
          let cNama =
            val.nama.toLowerCase().indexOf(this.searchTerm.toLowerCase()) != -1;
          return cNama;
        });
      }

      let show = [];
      for (let i = 0; i < 10; i++) {
        if (siswa[(this.pagination.current - 1) * 10 + i]) {
          show.push(siswa[(this.pagination.current - 1) * 10 + i]);
        }
      }
      this.pagination.max = Math.ceil(siswa.length / 10);
      this.filteredSiswa = show;
    },
    async getMurabbi() {
      let resp = await this.$http.get(`/hasil/rekaptahfidz/getmurabbi`);
      this.murabbi = resp.data;
    },
  },
};
</script>

<style lang="scss" scoped></style>
